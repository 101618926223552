<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="服务活动填报表" name="first">
      <div class="flex marl_30">
        <div style="
              width: 100px;
              line-height: 40px;
              position: relative;
              font-size: 14px;
            ">
          关键词搜索
        </div>
        <div class="flex" style="
              width: 400px;
              height: 40px;
              border: 1px solid #e6e6e6;
              border-radius: 6px;
            ">
          <input type="text" v-model="search" placeholder="请输入内容" style="
                padding: 10px;
                border: none;
                  outline:none;
                width: 370px;
                border: 1px solid transparent;
                border-radius: 6px;
              " 
              />
          <!-- <el-input placeholder="请输入内容"></el-input> -->
          <i class="el-icon-search" style="
                font-size: 20px; 
                line-height: 40px;
                border: 1px solid transparent;
                border-radius: 6px;
              "></i>
        </div>

        <div class="marl_30" style="
              width: 198px;
              height: 40px;
              background: #ff6618;
              border-radius: 6px;
              line-height: 40px;
              text-align: center;
              color: #fff;
            " @click="add()">
          添加服务活动填报表
        </div>
      </div>

      <div>
        <el-table :data="TableData" style="width: 100%">
          <el-table-column prop="id" label="ID" min-width="180" align="center"></el-table-column>
          <el-table-column prop="activity_name" label="活动名称" min-width="180" align="center"></el-table-column>
          <el-table-column prop="service_category_name" label="活动类型" min-width="180" align="center"></el-table-column>
          <el-table-column prop="type" label="类型" min-width="180" align="center">
          </el-table-column>
          <el-table-column align="center" prop="mode" label="方式" min-width="180"></el-table-column>
          <el-table-column prop="information" label="月报详情" min-width="180" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="getlooklist(scope.row)">查看月报详情</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="activity_time" label="活动时间" min-width="180" align="center">
          </el-table-column>
          <el-table-column prop="create_time" label="报送时间" min-width="180" align="center">
          </el-table-column>
          <el-table-column prop="content" label="操作" min-width="180" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="amend(scope.row)">修改服务活动填报表</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-tab-pane>

    <el-dialog title="服务月报详情" :visible.sync="looklogin" width="1200px" center>
      <div class="padb_20 ">
        <div class="flex align_center">
          <div>活动名称:</div>
          <div>{{ looklist.activity_name }}</div>
        </div>
      </div>
      <div class="padb_20">
        <div class="flex align_center">
          <div>活动类别:</div>
          <div>{{ looklist.service_category_id }}</div>
        </div>
      </div>
      <div class="padb_20">
        <div class="flex align_center">
          <div>范围:</div>
          <div>{{ looklist.scope }}</div>
        </div>
      </div>
      <div class="padb_20">
        <div class="flex align_center">
          <div>活动时间:</div>
          <div>{{ looklist.activity_time }}至{{ looklist.activity_end_time }}</div>
        </div>
      </div>
      <div class="padb_20">
        <div class="flex align_center">
          <div>类型:</div>
          <div>{{ looklist.type }}</div>
        </div>
      </div>
      <div class="padb_20">
        <div class="flex align_center">
          <div>方式:</div>
          <div>{{ looklist.mode }}</div>
        </div>
      </div>
      <div class="padb_20">
        <div class="flex align_center">
          <div>规模（家）:</div>
          <div>{{ looklist.scale }}</div>
        </div>
      </div>
      <div class="padb_20">
        <div class="flex align_center">
          <div>人数（家）:</div>
          <div>{{ looklist.people_num }}</div>
        </div>
      </div>
      <div class="padb_20">
        <div class="flex align_center">
          <div>是否收费:</div>
          <div>{{ looklist.charge == 1 ? '是' : '否' }} </div>
          <div v-if="looklist.price"> {{ looklist.price }}元</div>
        </div>
      </div>

      <div class="padb_20">
        <div class="flex align_center">
          <div>指导部门:</div>
          <div>{{ looklist.department }}</div>
        </div>
      </div>

      <div class="padb_20">
        <div class="flex align_center">
          <div>预算（元）:</div>
          <div>{{ looklist.budget }}</div>
        </div>
      </div>
      <div class="padb_20">
        <div class="flex align_center">
          <div>费用来源:</div>
          <div>{{ looklist.source }}</div>
        </div>
      </div>

      <div class="padb_20">
        <div>签到表:</div>
        <div class="flex align_center">
          <div v-for="(item, index) in img1">
            <img :src="item" alt="" style="width: 150px; height: 150px;">

          </div>
        </div>
      </div>

      <div class="padb_20">
        <div>现场照片:</div>
        <div class="flex align_center">
          <div v-for="(item, index) in img2">
            <img :src="item" alt="" style="width: 150px; height: 150px;">

          </div>
        </div>
      </div>
      <div class="padb_20">
        <div>活动通知:</div>
        <div class="flex align_center">
          <div v-for="(item, index) in img3">
            <img :src="item" alt="" style="width: 150px; height: 150px;">

          </div>
        </div>
      </div>
      <div class="padb_20">
        <div>总结:</div>
        <div class="flex align_center">
          <div v-for="(item, index) in img4">
            <img :src="item" alt="" style="width: 150px; height: 150px;">

          </div>
        </div>

      </div>
      <div class="padb_20">
        <div>亮点:</div>
        <div class="flex align_center">
          <div v-for="(item, index) in img5">
            <img :src="item" alt="" style="width: 150px; height: 150px;">

          </div>
        </div>
      </div>

    </el-dialog>




    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="paginations.page_index" :page-sizes="paginations.page_sizes" :page-size="paginations.page_size"
      :layout="paginations.layout" :total="paginations.total">
    </el-pagination>
  </el-tabs>
</template>
<script>
export default {
  data() {
    return {
      activeName: "first",
      search: "",
      TableData: [],
      looklist: [],
      looklogin: false,
      allTableData: [],
      img1: [],
      img2: [],
      img3: [],
      img4: [],
      img5: [],
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
    }
  },
  created() {
    this.getactivity()
  },
  methods: {
    getlooklist(item) {
      console.log(item)
      let data = {
        token: localStorage.eleToken,
        id: item.id
      };
      this.$get("/service_activity_lst_d", data).then((res) => {
        this.looklogin = true
        this.looklist = res.result
        let img = res.result.img
        img.forEach(item => {
          if (item.type == 1) {
            this.img1.push(item.img)
            console.log(this.img1)
          } else if (item.type == 2) {
            this.img2.push(item.img)
          }
          else if (item.type == 3) {
            this.img3.push(item.img)
          }
          else if (item.type == 4) {
            this.img4.push(item.img)
          }
          else if (item.type == 5) {
            this.img5.push(item.img)
          }

        });
        console.log(this.img1, 'this.img1')


        console.log(res, 'res')
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    add() {



      let data = {
        token: localStorage.eleToken,
      };
      this.$post("mechanism_currency", data).then((res) => {
        console.log(res, "res");
        if (res.result.audit_status === 2) {
          this.$router.push("/addsweveWriting")
        } else if (
          res.result.audit_status === 0 ||
          res.result.audit_status === 3
        ) {
          this.authentication();
        } else if (res.result.audit_status === 1) {
          this.$message({
            type: "info",
            message: "审核中",
          });
        }
      });


    },
    amend(item) {
      console.log(item.id, 'item')
      this.$router.push({ path: "/upsweveWriting", query: { ID: item.id } });
    },

    getactivity(item) {
      let data = {
        token: localStorage.eleToken,
        keyword: this.search,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
      };
      this.$post("/service_activity_lst", data).then((res) => {
        this.allTableData = res.result;
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }
       
        if (this.allTableData.length != 0) {
          this.TableData = this.allTableData.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.TableData = [];
        }
        console.log(this.allTableData, "this.allTableData");
      });
    },


    authentication() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("/enterprise_user_status", data).then((res) => {
        console.log(res, "res");
        if (res.status === 1) {
          this.gonext();
        } else if (res.status === 2) {
          // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
          // location.href = "http://sso.smeqh.cn:9000/realname/index";
          // this.$message({
          //   type: "info",
          //   message: "您的账号还未实名认领，请认领通过之后再进行下一步操作",
          // });
        }
      });
    },

    // 分页
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.getactivity()
      this.TableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },
    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      this.getactivity(page)
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.TableData = tables;
      }
    },



  },

}

</script>

<style lang="less" scoped>
::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}

//修改菜单颜色
/deep/.el-tabs__item.is-active {
  color: #ff7129 !important;
}

/deep/.el-tabs__active-bar {
  background-color: #ff7129 !important;
}

/deep/.el-tabs__item:hover {
  color: #ff7129 !important;
}
</style>